// src/App.tsx

import React from 'react';
import SplashPage from './components/SplashPage';
import SewageTable from './components/SewageTable';

const App: React.FC = () => {
  return (
    <div className="App">
      <h1>WaterWise</h1>
      <SplashPage />
      <SewageTable /> {/* Ensure the updated SewageTable is included */}
    </div>
  );
};

export default App;
