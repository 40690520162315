import { SewageExceedance } from '../types/SewageExceedance';

const API_URL = '/api/sewage';

// Existing method to fetch all exceedances
export const getAllExceedances = async (): Promise<SewageExceedance[]> => {
  try {
    const response = await fetch(`${API_URL}/all`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log('Fetched sewage data:', data); // Debugging information
    return data;
  } catch (error) {
    console.error('Error fetching sewage data:', error);
    return [];
  }
};

// New method to fetch average exceedances for a given municipality
export const getAverageExceedancesByMunicipality = async (municipality: string): Promise<number> => {
  try {
    const response = await fetch(`${API_URL}/average/${municipality}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log('Fetched average exceedances:', data); // Debugging information
    return data;
  } catch (error) {
    console.error('Error fetching average exceedances:', error);
    return 0;
  }
};

// Existing function for the data service fetch
export const getDataFromDS = async () => {
  try {
    console.log('Attempting to fetch data from /data');
    const response = await fetch('/python/data');
    console.log('Response received:', response.status, response.statusText);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log('Fetched data from DS:', data);
    return data;
  } catch (error) {
    console.error('Error fetching data from data service:', error);
    throw error;
  }
};
