import React, { useState, useEffect } from 'react';
// Import the service functions
import { getAllExceedances, getAverageExceedancesByMunicipality, getDataFromDS } from '../services/SewageService';
import { SewageExceedance } from '../types/SewageExceedance';

const SewageTable: React.FC = () => {
  const [sewageData, setSewageData] = useState<SewageExceedance[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [municipality, setMunicipality] = useState<string>(''); // State for municipality input
  const [averageExceedances, setAverageExceedances] = useState<number | null>(null); // State for storing average exceedances

  // Fetch sewage data initially
  useEffect(() => {
    const fetchSewageData = async () => {
      try {
        console.log('Fetching sewage data...');
        await getDataFromDS();  // Existing fetch from the data service
        const data = await getAllExceedances();
        console.log('Sewage data received:', data); // Debugging information
        setSewageData(data);
      } catch (error) {
        console.log('Error fetching sewage data:', error);
        setError('Error fetching sewage data');
      } finally {
        setLoading(false);
      }
    };
    fetchSewageData();
  }, []);

  // Fetch average exceedances by municipality
  const fetchAverageExceedances = async (municipality: string) => {
    setLoading(true); // Set loading state while fetching
    try {
      const avg = await getAverageExceedancesByMunicipality(municipality);
      setAverageExceedances(avg); // Set the fetched average exceedances
    } catch (error) {
      setError('Error fetching average exceedances');
    } finally {
      setLoading(false); // Remove loading state
    }
  };

  if (loading) {
    return <p data-testid="loading-spinner">Loading data...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  if (sewageData.length === 0) {
    return <p className="no-data">No sewage exceedance data available.</p>;
  }

  return (
    <div className="sewage-table-container">
      <h2 className="title">Sewage Exceedance Data</h2>

      {/* Added section for fetching average exceedances */}
      <div>
        <label htmlFor="municipality">Select Municipality:</label>
        <input
          type="text"
          id="municipality"
          value={municipality}
          onChange={(e) => setMunicipality(e.target.value)}
        />
        <button onClick={() => fetchAverageExceedances(municipality)}>Get Average Exceedances</button>
      </div>

      {/* Display average exceedances */}
      {averageExceedances !== null && (
        <div>
          <h3>Average Exceedances for {municipality}:</h3>
          <p>{averageExceedances}</p>
        </div>
      )}

      {/* Existing sewage data table */}
      <table className="sewage-table">
        <thead>
          <tr>
            <th>Municipality</th>
            <th>Exceedances</th>
          </tr>
        </thead>
        <tbody>
          {sewageData.map((item) => (
            <tr key={item.id}>
              <td>{item.municipality}</td>
              <td>{item.exceedances}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SewageTable;